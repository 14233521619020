import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import ImageComponent from "../../../components/inputs/ImageComponent";
import moment from "moment";
import {
  customizeVideoUrl,
  findObjectKeyByValue,
  titleCase,
} from "../../../utils/helper";

import NoDataComponent from "../../../components/layouts/NoDataComponent";
import {
  BENIFICIARY_LOG_STATUS,
  FOLLOWUP_TYPE,
  FOLLOW_UP_STATUS,
  HEARING_STATE,
  SPEECH_STATE,
} from "../../../utils/beneficiary.constant";
import { useEffect, useState } from "react";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import {
  CheckCircle,
  ExpandMore,
  VideoCall,
  Videocam,
  VideoCameraFront,
  VideocamOff,
  Warning,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import UpdateVideoController from "../../followup/UpdateVideoController";
import {
  SNACK_BAR_VARIETNS,
  USER_ROLES,
  VIDEOS_STATUS,
} from "../../../utils/constants";
import SubmitButton from "../../../components/button/SubmitButton";
import UpdateVideoRatingModal from "../videos/UpdateVideoRatingModal";
import UpdateVideoStatusModal from "../videos/UpdateVideoStatusModal";
import MessageDilog from "../../../components/MessageDilog";
import { updateVideoStatusApi } from "../../../apis/beneficiary.api";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { callApiAction } from "../../../store/actions/commonAction";

const FollowUpContainer = styled(Box)(({ theme }) => ({
  border: "1px solid " + theme.palette.primary.main,
  padding: theme.spacing(2),
  background: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius * 2,

  width: "100%",
}));

const FollowUpDetails = ({ data, loading, fetchFollowUps, setData }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const applyRatingAndTags = (log) => {
    dispatch(
      openModal(
        <UpdateVideoRatingModal
          beneficiary_name={log?.name}
          followup_id={log?._id}
          video_id={log?.videos[0]?._id}
          rating={log?.videos[0]?.ratings}
          tags={log?.videos[0]?.tags}
          callBack={(fields) => {
            const video = { ...log?.videos };
            video["ratings"] = fields?.rating;
            video["tags"] = fields?.tags;
            // setParams({ ...log, videos: video });
          }}
        />,
        "sm",
        undefined,
        "update-video-rating"
      )
    );
    // return (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <Rating
    //         name="rating-controlled"
    //         value={params.videos?.ratings}
    //         readOnly
    //       />
    //       <IconButton onClick={onclick}>
    //         <Edit />
    //       </IconButton>
    //     </Box>
    //   );
  };

  const rejectRatingAndTags = (log) => {
    dispatch(
      openModal(
        <UpdateVideoStatusModal
          // params={log}
          beneficiary_name={log?.name}
          followup_id={log?._id}
          video_id={log?.videos[0]?._id}
          video_status={log?.videos[0]?.video_status}
          callBack={(fields) => {
            const video = { ...log?.videos };
            video["video_status"] = fields?.video_status;
          }}
        />,
        "sm",
        false,
        "update-video-status"
      )
    );
    // dispatch(
    //   openModal(
    //     <MessageDilog
    //       title={"Are you sure to update beneficiary video status " + log?.beneficiary_id?.name}
    //       message="On click of Update Status Manager Rejected"
    //       onSubmit={onSubmit}
    //     />,
    //     "sm"
    //   )
    // );
    // }
  };
  // const followup_id = log?._id;
  // const video_id = log?.videos?._id;
  // const video_status = log?.videos?.video_status;
  // const [loading, setLoading] = useState(false);
  // const [fields, setFields] = useState({
  //   // rating,
  //   // tags,
  //   video_id,
  //   followup_id,
  //   video_status,
  //   err: "",
  // });
  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await updateVideoStatusApi({ ...fields,video_status:VIDEOS_STATUS.MANAGER_REJECT }),
  //       (response) => {
  //         dispatch(
  //           callSnackBar(
  //             "Video Status Updated successfully.",
  //             SNACK_BAR_VARIETNS.suceess
  //           )
  //         );
  //         setLoading(false);
  //         // onClose();
  //         // callBack(fields);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );

  useEffect(() => {
    if (
      !data.followups ||
      !Array.isArray(data.followups) ||
      data.followups.length == 0
    ) {
      fetchFollowUps();
    }
  }, []);

  const onCreateVideoClick = (id) => {
    dispatch(
      openModal(
        <UpdateVideoController
          id={id}
          callBack={(response) => {
            const followUps = data.followups ? [...data.followups] : [];

            let i = 0;
            for (let item of followUps) {
              if (item._id == id)
                followUps[i]["videos"] = [
                  {
                    url: customizeVideoUrl(response.videos),
                  },
                ];
              i++;
            }

            setData({ ...data, followups: followUps });
          }}
        />,
        "sm",
        undefined,
        "video-update"
      )
    );
  };
  return (
    <>
      {loading && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}
      {!loading && (
        <>
          <Box p={2}>
            {data.followups && data.followups.length > 0 ? (
              <>
                {data.followups.map((log) => (
                  <Accordion sx={{}}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={log._id}
                      id={log._id}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box mb={1}>
                          <Typography variant="h6" mb={1}>
                            Followup {log.followup_no}{" "}
                            <Chip
                              size="small"
                              label={findObjectKeyByValue(
                                log.type,
                                FOLLOWUP_TYPE
                              )}
                            />
                                        {log.videos[0]?.url
                                        ? <IconButton sx={{ fontSize: "inherit" }} color="primary"><Videocam /></IconButton> : <IconButton sx={{ fontSize: "inherit" }}  color="error"><VideocamOff /></IconButton>       
                                        
                                     }
                          </Typography>
                          {log.status == FOLLOW_UP_STATUS.FOLLOWED_UP ? (
                            <Typography variant="body2">
                              Followup Date:{" "}
                              {moment(log.followedup_date).format("DD/MM/YYYY")}
                            </Typography>
                          ) : (
                            <Typography variant="body2">
                              Scheduled Date:{" "}
                              {moment(log.scheduled_date).format("DD/MM/YYYY")}
                            </Typography>
                          )}
                        </Box>
                        {user.data.role == USER_ROLES.SIT_TEAM &&
                          (log.followup_no == 1 ||
                            log.followup_no == 6 ||
                            log.followup_no == 8) && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="h6" lineHeight="100%" mr={2}>
                                {log.videos?.length > 0 ? (
                                  <VideoCameraFront color="info" />
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      onCreateVideoClick(log._id);
                                    }}
                                  >
                                    <VideoCall color="error" />{" "}
                                  </IconButton>
                                )}
                              </Typography>
                              <Typography variant="h6" lineHeight="100%">
                                {log.status == FOLLOW_UP_STATUS.FOLLOWED_UP ? (
                                  <CheckCircle
                                    fontSize="inherit"
                                    color="success"
                                  />
                                ) : (
                                  <Warning fontSize="inherit" color="warning" />
                                )}
                              </Typography>
                            </Box>
                          )}

                        {user.data.role == USER_ROLES.SIT_MANAGER &&
                          (log.followup_no == 1 ||
                            log.followup_no == 6 ||
                            log.followup_no == 8) &&
                          log?.videos[0]?.url != null &&
                          log?.videos[0]?.video_status != null && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: 2,
                              }}
                            >
                              {log?.videos[0]?.video_status ==
                              VIDEOS_STATUS.PENDING ? (
                                <>
                                  <Button
                                    variant="contained"
                                    onClick={() => applyRatingAndTags(log)}
                                  >
                                    Approve
                                  </Button>

                                  <Button
                                    variant="contained"
                                    onClick={() => rejectRatingAndTags(log)}
                                  >
                                    Reject
                                  </Button>
                                </>
                              ) : (
                                <Chip
                                  label={
                                    log?.videos[0]?.video_status ==
                                    VIDEOS_STATUS.MANAGER_APPROVE
                                      ? "Approved"
                                      : "Rejected"
                                  }
                                  color={
                                    log?.videos[0]?.video_status ==
                                    VIDEOS_STATUS.MANAGER_APPROVE
                                      ? "success"
                                      : "error"
                                  }
                                  sx={{ m: 2, p: 2 }}
                                />
                                //  </>
                              )}
                            </Box>
                          )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                          <Typography variant="body2" fontWeight="bold">
                            {" "}
                            Hearing Progress
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant="body2">
                            {" "}
                            {findObjectKeyByValue(
                              log.hearing_state,
                              HEARING_STATE
                            ) ?? "NA"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant="body2" fontWeight="bold">
                            {" "}
                            Speech Progress
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant="body2">
                            {findObjectKeyByValue(
                              log.speech_state,
                              SPEECH_STATE
                            ) ?? "NA"}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} md={3}>
                          <Typography variant="body2" fontWeight="bold">
                            {" "}
                            Assigned Person:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography
                            variant="body2"
                            textTransform="capitalize"
                          >
                            {log.assigned_to && log.assigned_to.name
                              ? log.assigned_to.name
                              : "NA"}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} md={3}>
                          <Typography variant="body2" fontWeight="bold">
                            {" "}
                            Remarks:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant="body2">
                            {log.last_remarks ?? "NA"}
                          </Typography>
                        </Grid>

                        {log.videos && log.videos.length > 0 && (
                          <>
                            {log.videos.map((item) => {
                              return (
                                <Box
                                  key={item.url}
                                  sx={(theme) => ({ width: "100%" })}
                                  p={3}
                                >
                                  <Button
                                    disableElevation
                                    sx={{ mb: 2 }}
                                    variant="contained"
                                    target="_blank"
                                    href={item.url}
                                  >
                                    Open Video
                                  </Button>

                                  <iframe
                                    style={{
                                      width: "100%",
                                      minHeight: "300px",
                                      maxHeight: "500px",
                                    }}
                                    src={item.url}
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                  ></iframe>
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            ) : (
              <NoDataComponent message="No followup found" />
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default FollowUpDetails;
