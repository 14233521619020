import {
    Autocomplete,
    Box,
    Grid,
    MenuItem,
    Paper,
    Skeleton,
    Typography,
    styled,
    useTheme,
    ButtonBase,
  } from "@mui/material";
  
  import { Bar, Pie } from "react-chartjs-2";
  import Counts from "../../../dashboard/Counts";
  import { formatNumberCustomPattern } from "../../../../utils/helper";
//   import { getBeneficiaryVideosCount } from "../../../apis/beneficiary.api";
  import { useDispatch } from "react-redux";
  import { useEffect, useState } from "react";
  import { callApiAction } from "../../../../store/actions/commonAction";
  import ClickButton from "../../../../components/button/ClickButton";
import { getTotalPreAndPostVideosCount } from "../../../../apis/beneficiary.api";
  
  const CountBoxContainer = styled(Box)(({ theme, active }) => ({
    background: active ? theme.palette.primary.main : theme.palette.light.main,
    border: "1px solid " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    "*": {
      color: active ? theme.palette.light.main + "!important" : "",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }));
  
  const CountBoxComponent = ({
    part,
    count,
    title,
    subtitle,
    active,
    onClick,
    loading,
  }) => {
    if (loading) {
      return (
        <Grid item sm={part} xs={6}>
          <Skeleton variant="rounded" width="100%" height="100px"></Skeleton>
        </Grid>
      );
    }
  
    return (
      <Grid item sm={part} xs={6}>
        <CountBoxContainer
          active={active}
          component={ButtonBase}
          onClick={onClick}
          sx={{ width: "100%" }}
        >
          <Box p={2}>
            <Typography color="primary" variant="h3" align="center">
              {formatNumberCustomPattern(count)}
            </Typography>
            <Typography variant="h6" fontWeight="bold" align="center">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="caption" fontWeight="bold" align="center">
                {subtitle}
              </Typography>
            )}
          </Box>
        </CountBoxContainer>
      </Grid>
    );
  };
  
  const TotalVideoCounts = ({
    count,
    // onInDirectVideosClicks,
    onPreVideosClicks,
    // onApproveVideosClicks,
    // onRejectVideosClicks,
    // onTotalVideosClicks,
    // filters,
    // setFilters,
  }) => {
  
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const fetchCountFun = () => {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await getTotalPreAndPostVideosCount({}),
          (response) => {
            setData(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    };
    useEffect(() => {
      fetchCountFun();
    },[]);
  
    if (loading)
      return (
        <>
          <Grid container spacing={2}>
            {[0, 1, 2].map((item) => (
              <Grid item xs={6} md={4} key={item}>
                <Skeleton
                  variant="rounded"
                  height="150px"
                  width="100%"
                ></Skeleton>
              </Grid>
            ))}
          </Grid>
        </>
      );

      return (
        <Grid container spacing={2}>
     <CountBoxComponent count={data?.totalVideos} title="Total Video"  part={12 / 3} />
           
            <CountBoxComponent onClick={() =>
              // console.log("VIDEOSTATUS",videoStatus)
              // setFilters({
              //   ...filters,
              //   videoStatus: ele.value,
              //   // status: undefined,
              //   pageNo: 1,
              // })
              onPreVideosClicks()
            }count={data?.totalPreVideos} title="Total Pre Video"  part={12 / 3} />
            <CountBoxComponent count={data?.totalPostVideos} title="Total Post Video"  part={12 / 3} />
          
        </Grid>
      );
  
    return <></>;
  };
  export default TotalVideoCounts;
  