import { memo, useCallback, useEffect, useRef, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";

import { closeModal, openModal } from "../../../store/actions/modalAction";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
// import CreateController from "./CreateController"
import {
  Check,
  Close,
  Delete,
  Done,
  Edit,
  Info,
  MoreVert,
} from "@mui/icons-material";
import moment from "moment";
import ListBeneficiaryUi from "./ListBeneficiaryUi";
import {
  getBeneficiaryVideoByEventApi,
  getEventApi,
} from "../../../apis/event.api";

const ListBeneficiaryController = ({ id }) => {
  const dispatch = useDispatch();

  const title = "Video Summary ";
  const modalKey = "event-beneficiary";
  const fetchApi = getBeneficiaryVideoByEventApi;
  const [countF1,setCountF1] = useState(0)
  const [countF6,setCountF6] = useState(0)
  const [countF8,setCountF8] = useState(0)

  // const columns = useMemo(() => [
  //     { id: 0, fieldName: 'camp_date', label: 'Camp Date', align: "left", sort: true, renderValue: (fields) => fields.camp_date ? moment(fields.camp_date).format("DD/MM/YYYY") : "NA" },
  //     { id: 0, fieldName: 'event_id', label: 'events', align: "left", sort: true, renderValue: (fields) => fields?.event_id?.name },
  //     { id: 2, fieldName: 'createdAt', label: 'Created Date', align: "left", sort: true, renderValue: (fields) => fields.createdAt ? moment(fields.createdAt).format("DD/MM/YYYY") : "NA" },
  //     { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, renderValue: (params) => createFullName(params.surname, params.name) },
  //     { id: 3, fieldName: 'phone', label: 'Phone No.', align: "left" },
  //     { id: 3, fieldName: 'district.name', label: 'District', align: "left", sort: true, renderValue: (params) => params.district?.name },
  //     { id: 8, fieldName: 'status', label: 'Status', align: "left", sort: true, renderValue: (params, setParams) => <Chip size="small" label={findObjectKeyByValue(params.status, BENIFICIARY_STATUS)} /> },
  //     { id: 7, fieldName: 'action', label: 'Action', align: "left",
  //         //  renderValue: (params, setParams) =>
  //     // <BeneficiaryActionComponent params={params} setParams={setParams} />
  //  },

  //     // { id: 8, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <DeleteBeneficiaryButton params={params} setParams={setParams} /> },
  // ], [dispatch]);

  const columns = useMemo(
    () => [
      {
        id: 2,
        fieldName: "name",
        label: "Beneficiary Name",
        align: "left",
        sort: true,
      },

      {
        id: 4,

        fieldName: "total_followup",
        minWidth: "200px",
        label: "Followup 1",
        align: "left",
        renderValue: (params, setParams) => (
          <>
            
            {params.total_followup
              .filter((item) => item.followup_no == 1)
              .map((item) =>
                item.videos ? <><Done color="success" /> {setCountF1((prev)=>prev+1)} </> : <Close color="error" />
              )}
          </>
        ),
      },
      {
        id: 5,

        fieldName: "total_followup",
        minWidth: "200px",
        label: "Followup 6",
        align: "left",
        renderValue: (params, setParams) => (
          <>
            {
                params.total_followup
                .filter((item) => item.followup_no == 6)
                .map((item) =>
                  item.videos ? (
                    <>
                    <Done color="success" />
                    {setCountF6((prev)=>prev+1)}
                    </>
                  ) : (
                    <Close color="error" />
                  )
                )
            }
          </>
        ),
      },
      {
        id: 6,

        fieldName: "total_followup",
        minWidth: "200px",
        label: "Followup 8",
        align: "left",
        renderValue: (params, setParams) => (
          <>
            {
                params.total_followup
                .filter((item) => item.followup_no == 8)
                .map((item) =>
                  item.videos ? (
                    <>
                    <Done color="success" />
                    {setCountF8((prev)=>prev+1)}
                    </>
                  ) : (
                    <Close color="error" />
                  )
                )
            }
          </>
        ),
      },
    ],
    [dispatch]
  );

  
  // do not change
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "camp_date",
    id: id,
    sortDirection: -1,
  });

  const [loading, setLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = (isExport) => {
    if (!isExport) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters }),
          (response) => {
            console.log("Responsess",response)
            setCountF6(0)
            setCountF1(0)
            setCountF8(0)
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters, csv: true }),
          (response) => {
            setexportLoading(false);
          },
          (err) => {
            setexportLoading(false);
          },
          true
        )
      );
    }
  };
  
  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListBeneficiaryUi
        countF1={countF1}
        countF6={countF6}
        countF8={countF8}
        modalKey={modalKey}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};

export default ListBeneficiaryController;
