import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { callApiAction } from "../../../../store/actions/commonAction";
import { getBeneficiaryVideoListApi, getBeneficiaryVideosCount, getPreVideosApi, updateVideoStatusApi } from "../../../../apis/beneficiary.api";

import { createFullName, findObjectKeyByValue } from "../../../../utils/helper";
import { Draw, Edit, People, VideoCall } from "@mui/icons-material";
import { Box, Chip, IconButton, Rating } from "@mui/material";
import ListPreVideoUi from "./ListPreVideoUi";


const ListPreVideoController = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const title = "Total Pre Videos";
  const modalKey = "pre-videos";

  const fetchApi = getPreVideosApi;

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "createdAt",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (fields) =>
          moment(fields?.createdAt).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) =>
          createFullName(
            params?.surname,
            params?.name
          ),
      },
      {
        id: 2,
        fieldName: "before_beneficiary_video",
        label: "Pre Video 1",
        align: "left",
        renderValue: (params) => 
        <IconButton  disableElevation sx={{ mb: 2 }} variant="contained" target="_blank" href={params?.before_beneficiary_video}>
          <VideoCall />{" "}
        </IconButton>
        // <VideoViewButton params={params} />,
      },
      {
        id: 3,
        fieldName: "after_beneficiary_video",
        label: "Pre Video 2",
        align: "left",
        renderValue: (params) => 
          <IconButton  disableElevation sx={{ mb: 2 }} variant="contained" target="_blank" href={params?.after_beneficiary_video}>
            <VideoCall />{" "}
          </IconButton>
      },  
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "createdAt",
    sortDirection: -1,
    camp: null,
    country: null,
    state: null,
    district: null,
    // status: null,
    taluka: null,
    product:null,
    donation_event_id: null,

  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
            console.log("REsponsess",response)
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  console.log("list",list);

  return (
    <>
      <ListPreVideoUi
        modalKey={modalKey}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListPreVideoController;
