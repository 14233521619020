import { memo, useCallback, useEffect, useState } from "react"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal, openModal } from "../../../store/actions/modalAction"



import { getBeneficiaryApi } from "../../../apis/beneficiary.api"

import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import BeneficiaryFromCampUi from "./BeneficiaryFromCampUi"
import BeneficiaryRegionUi from "./BeneficiaryFromRegionUi"
import { REGIONS_TYPE } from "../../../utils/constants"
import { findObjectKeyByValue } from "../../../utils/helper"
import BeneficiaryFromUDISE from "./BeneficiaryFromUDISE"
import BeneficiaryFromEventUi from "./BeneficiaryFromEventUi"





const BeneficiaryFetchController = ({ callBack = () => { }, type = "camp", status = BENIFICIARY_STATUS.VALIDATED }) => {
    const dispatch = useDispatch()



    const title = "Beneficiary Data By Camp"
    const modalKey = 'beneficiary-by-paramas'
    const fetchApi = getBeneficiaryApi


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name','surname','phone'],
        sort: 'updatedAt',
        isDropDown:true,
        camp: null,
        status,
        type: null,
        all: true,
        nuResponse: "true",
        donation_event_id:null,
        sortDirection: -1

    })



    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    const [selectedBeneficiary,setSelectedBeneficiary] = useState([])







    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    // const onSubmit = (selectedItems) => {
    //     e.preventDefault()e,
    //     console.log("Seelcetde itemss",selectedItems)
    //     callBack(selectedItems)
    //     dispatch(closeModal(modalKey))
    // }
    const onSubmit = (e,selectedItems) => {
        e.preventDefault()
        callBack(selectedItems);
        dispatch(closeModal(modalKey));
      };


    useEffect(() => {
        if (type == 'region') {
            if (filters.type && filters[findObjectKeyByValue(filters.type, REGIONS_TYPE).toLowerCase()]) {
                fetchList()
            } else {
                setList([])
            }
        }

        if (type == 'udise') {
            if (filters.udise && filters.udise.length > 4) {
                fetchList()
            } else {
                setList([])
            }
        }

        if (type == 'camp') {
            if (filters.camp) {
                fetchList()
            } else
                setList([])
        }

        if (type == 'event') {
            if (filters.donation_event_id) {
                fetchList()
            } else
                setList([])
        }
    }, [filters])

    if (type == 'region')
        return (
            <BeneficiaryRegionUi
                title={"Beneficiary Data By Region "}
                loading={loading}
                list={list}
                setList={setList}
                filters={filters}
                setFilters={setFilters}
                onSubmit={onSubmit}
                modalKey={modalKey}
             />
        )
    if (type == 'event')
        return (
            <BeneficiaryFromEventUi
                title={"Beneficiary Data By Event "}
                loading={loading}
                list={list}
                filters={filters}
                setFilters={setFilters}
                onSubmit={onSubmit}
                modalKey={modalKey}
            />
        )

    if (type == 'udise')
        return (
            <BeneficiaryFromUDISE
                title={"Beneficiary Data By UDISE No. "}
                loading={loading}
                list={list}
                filters={filters}
                setFilters={setFilters}
                onSubmit={onSubmit}
                modalKey={modalKey}
            />
        )
    if (type == 'camp')

        return (
            <BeneficiaryFromCampUi
                title={title}
                loading={loading}
                list={list}
                filters={filters}
                setFilters={setFilters}
                onSubmit={onSubmit}
                modalKey={modalKey}
            />
        )
}
export default BeneficiaryFetchController