import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ListUi from "./ListVideoUi";

import { callApiAction } from "../../../../store/actions/commonAction";

import { getBeneficiaryVideoListApi, getBeneficiaryVideosCount, updateVideoStatusApi } from "../../../../apis/beneficiary.api";

import { createFullName, findObjectKeyByValue } from "../../../../utils/helper";
import { closeModal, openModal } from "../../../../store/actions/modalAction";
import VideoViewer from "../../../videos/VideoViewer";
import { Draw, Edit, People, VideoCall } from "@mui/icons-material";
import DetailedViewController from "../../detailedview/DetailedViewController";
import { Box, Chip, IconButton, Rating } from "@mui/material";
// import UpdateVideoUrlModal from "./UpdateVideoUrlModal";
// import UpdateVideoRatingModal from "./UpdateVideoRatingModal";
import {
  USER_ROLES,
  VIDEOS_STATUS,
  VIDEOS_TAGS,
} from "../../../../utils/constants";
import { findAllByAltText } from "@testing-library/react";
import SubmitButton from "../../../../components/button/SubmitButton";
import MessageDilog from "../../../../components/MessageDilog";
import { getMainBeneficiaryVideoListApi } from "../../../../apis/beneficiary.api";
import UpdateVideoRatingModal from "../UpdateVideoRatingModal";
import ListPreVideoController from "./ListPreVideoController";

const VideoViewButton = ({ params, setParams }) => {
  console.log("uuuuu", params);
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <VideoViewer params={params} />,
        "md",   
        undefined,
        "video-viewer"
      )
    );
  };
  return (
    <IconButton onClick={onclick}>
      {" "}
      <VideoCall />{" "}
    </IconButton>
  );
};
const NameButton = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <DetailedViewController id={params?.beneficiary_id?._id} />,
        "md",
        undefined,
        "beneficiary-detailed-view"
      )
    );
  };
  return (
    <IconButton onClick={onclick}>
      <People />
    </IconButton>
  );
};


const ListVideoController = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const title = "Beneficiary Videos";
  const modalKey = "beneficiary";

  const fetchApi = getMainBeneficiaryVideoListApi;

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "videos.date",
        label: "Video Taken Date",
        align: "left",
        sort: true,
        renderValue: (fields) =>
          moment(fields.videos?.date).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) =>
          createFullName(
            params?.beneficiary_id?.surname,
            params?.beneficiary_id?.name
          ),
      },
      {
        id: 2,
        fieldName: "video",
        label: "Video",
        align: "left",
        renderValue: (params) => <VideoViewButton params={params} />,
      },
      {
        id: 3,
        fieldName: "name",
        label: "Beneficiary Detail",
        align: "left",
        renderValue: (params) => <NameButton params={params} />,
      },
      {
        id: 4,
        fieldName: "followup_no",
        label: "Followup No.",
        align: "left",
        // renderValue: (params) => <NameButton params={params} />,
      },
      {
        id: 5,
        fieldName: "videos.ratings",
        label: "Ratings",
        sort: true,
        align: "left",
        renderValue: (params, setParams) => (
        //   <Box sx={{ display: "flex", alignItems: "center" }}>
        //   <Rating
        //     name="rating-controlled"
        //     value={params.videos?.ratings}
        //     readOnly
        //   />
        // </Box>
        <Chip  size="small"
        p={2}
        color="primary"
        label={
          params?.videos?.ratings + " Star"
        } 
        />
        ),
      },
      {
        id: 6,
        fieldName: "videos.tags",
        label: "Tags",
        sort: true,
        align: "left",
        renderValue: (params, setParams) =>
          findObjectKeyByValue(params?.videos?.tags, VIDEOS_TAGS),
      },
      // {
      //   id: 5,
      //   fieldName: "videos.video_status",
      //   label: "Status",
      //   sort: true,
      //   align: "left",
      //   renderValue: (params, setParams) => (
      //     // console.log("VIDEOS",params?.videos?.tags)
      //     findObjectKeyByValue(params?.videos?.video_status,VIDEOS_STATUS)
      //   ),
      // },
      // user.data.role == USER_ROLES.SIT_MANAGER && {
      //   id: 6,
      //   fieldName: " ",
      //   label: "Action",
      //   sort: true,
      //   align: "left",
      //   // renderValue: (params, setParams) => (
      //   //   <ActionComponent params={params} setParams={setParams} />
      //   // ),
      // },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name", "udise_no"],
    sort: "videos.ratings",
    validated: true,
    nuResponse: null,
    donated: null,
    donation_event_id: null,
    tags:'',
    ratings:'',
    camp: null,
    country: null,
    state: null,
    district: null,
    status: null,
    taluka: null,
    sortDirection: -1,
    isNoVideo: false,
    product:null,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  
  const [countsLoading, setCountsLoading] = useState(false)
  const [count, setCount] = useState()

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

//   const fetchCounts = () => {
//     setCountsLoading(true)
//     dispatch(callApiAction(
//         async () => await getBeneficiaryVideosCount({ ...filters }),
//         (response) => {
//           console.log("RESPOJNS",response)
//             setCount(response)
//             setCountsLoading(false)
//         },
//         (err) => {
//             setCountsLoading(false)
//         }
//     ))
// }

  useEffect(() => {
    fetchList();
    // fetchCounts()
  }, [filters]);

  const onPreVideosClicks = ()=>{
     dispatch(openModal(<ListPreVideoController/>,"md",undefined,"pre-videos"))
  }

  return (
    <>
      <ListUi
        count={count}
        setCount={setCount}
        countsLoading={countsLoading}
        setCountsLoading={setCountsLoading}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
        onPreVideosClicks={onPreVideosClicks}
      />
    </>
  );
};
export default ListVideoController;
