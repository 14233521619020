import { OpenInNew } from "@mui/icons-material";
import {
  Autocomplete,
  styled,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  CircularProgress,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../../../components/layouts/PaddingBoxInDesktop";

import AsynSearchBar from "../../../../components/inputs/AsynSearchBar";
import CustomDialog from "../../../../components/layouts/common/CustomDialog";
import { useTheme } from "@emotion/react";
import { CenteredBox } from "../../../../components/layouts/OneViewBox";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";
import { getEventApi } from "../../../../apis/event.api";
import { StyledSearchBar } from "../../../../components/inputs/SearchBar";
import TimeRangeSelector from "../../../../components/layouts/common/TimeRangeSelector";
import { getCampApi } from "../../../../apis/camp.api";
import { getProductApi } from "../../../../apis/product.api";
import CreatableRegionComponent from "../../../../components/custom/CreateableRegionComponent";
import { REGIONS_TYPE } from "../../../../utils/constants";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListPreVideoUi = ({
  modalKey,  
  title,
  filters,
  setFilters,
  list,
  exportLoading,
  loading,
  onExportBtnClick,
  columns,
  callBackFun,
}) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme()
//   const data = list.length;
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={filters.err}
        // onSubmit={onSubmit}
        title={title}
        // onClose={onClose}
        // disableDirectClose={false}
        closeText={"Close"}
        // confirmText={"Update"}
      >
        {/* {loading ? <CenteredBox sx={{ minHeight: "200px" }}><CircularProgress /></CenteredBox> : */}
        
        <Box mb={3}>
    
          <FiltersBox mt={3}>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <TimeRangeSelector
                onChange={(newRange) => {
                  setFilters({ ...filters, ...newRange });
                }}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              pl={2}
            >
              <AsyncDropDown
                lazyFun={getCampApi}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    camp: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Camp"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              pl={2}
            >
              <AsyncDropDown
                lazyFun={getEventApi}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    donation_event_id: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Event"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getProductApi({ ...para, allStatus: true })}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
          </FiltersBox>
          <FilterTitleBox>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading}
                value={
                  typeof filters.country == "object" ? filters.country : null
                }
                label={"Select Country*"}
                type={REGIONS_TYPE.COUNTRY}
                parent={null}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    country: changedVal ? changedVal._id : null,
                    state: null,
                    district: null,
                    taluka: null,
                  });
                }}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.country || filters.country == null
                }
                value={typeof filters.state == "object" ? filters.state : null}
                type={REGIONS_TYPE.STATE}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    state: changedVal ? changedVal._id : null,
                    district: null,
                    taluka: null,
                  });
                }}
                parent={
                  filters.country && typeof filters.country == "object"
                    ? filters.country["_id"]
                    : filters.country
                }
                label={"Select State*"}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading || !filters.state || filters.state == null}
                value={
                  typeof filters.district == "object" ? filters.district : null
                }
                type={REGIONS_TYPE.DISTRICT}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    district: changedVal ? changedVal._id : null,
                    taluka: null,
                  });
                }}
                label={"Select District*"}
                parent={
                  filters.state && typeof filters.state == "object"
                    ? filters.state["_id"]
                    : filters.state
                }
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.district || filters.district == null
                }
                value={
                  typeof filters.taluka == "object" ? filters.taluka : null
                }
                type={REGIONS_TYPE.TALUKA}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    taluka: changedVal ? changedVal._id : null,
                  });
                }}
                label={"Select Taluka*"}
                parent={
                  filters.district && typeof filters.district == "object"
                    ? filters.district["_id"]
                    : filters.district
                }
              />
            </PaddingBoxInDesktop>
          </FilterTitleBox>
          <FiltersBox mb={3}>
            <PaddingBoxInDesktop
              mb={2}
              sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "flex-end",
                // "*": {
                //   justifyContent: "center",
                // },
              }}
              pl={2}
            >
              <AsynSearchBar
                fullWidth
                title="Search By  Name "
                size="small"
                placeholder={"Search By  Name"}
                defaultValue={filters.search}
                onChange={(changedVal) => {
                  setFilters({ ...filters, search: changedVal });
                }}
              />
                </PaddingBoxInDesktop>
            <Box></Box>
          </FiltersBox>
          <Box sx={{ minHeight: "300px" }} className="table">
            <DataTable
              columns={columns}
              rows={list ? list?.result : []}
              count={list?.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>

        </Box>
{/* } */}
      </CustomDialog>
    </>
  );
};
export default ListPreVideoUi;
