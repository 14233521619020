import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  ListItem,
  Tab,
  Tabs,
} from "@mui/material";
import { useCallback, useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../../components/layouts/OneViewBox";

import NoDataComponent from "../../../components/layouts/NoDataComponent";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { getCampApi } from "../../../apis/camp.api";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { REGIONS_TYPE } from "../../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../../utils/helper";
import { getRegionApi } from "../../../apis/region.api";
import { Check, CheckBox, Close, Done } from "@mui/icons-material";

const BeneficiaryRegionUi = ({
  filters,
  loading,
  setFilters,
  list,
  onSubmit,
  modalKey,
  title,
  setList
}) => {
  const dispatch = useDispatch();
  
  // const onBenificaryClick = useCallback((beneficiaryId) => {
  //     dispatch(openModal(<BeneficiaryDetailedViewController
  //         id={beneficiaryId}

  //     />, "md", false, "beneficiary-detailed-view"))
  // }, [])

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChipClick = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };
  
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        title={title}
        closeText="Close"
        onSubmit={(e)=>onSubmit(e,selectedItems)}
        confirmText="Add"
        dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
      >
        <Box
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          })}
        >
          <Box mt={1} mb={3}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  disableClearable
                  value={
                    filters.type
                      ? findObjectKeyByValue(filters.type, REGIONS_TYPE)
                      : null
                  }
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      type: newVal ? newVal._id : null,
                      parent: null,
                    });
                  }}
                  options={[
                    ...Object.keys(REGIONS_TYPE).map((key) => ({
                      label: titleCase(key),
                      _id: REGIONS_TYPE[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Region Type*"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {filters.type && (
                  <AsyncDropDown
                    key={filters.type}
                    lazyFun={async (para) => {
                      return await getRegionApi({
                        type: filters.type,
                        ...para,
                      });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        [findObjectKeyByValue(
                          filters.type,
                          REGIONS_TYPE
                        ).toLowerCase()]: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={
                          "Select " +
                          findObjectKeyByValue(filters.type, REGIONS_TYPE)
                        }
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          {loading ? (
            <CenteredBox>
              <CircularProgress size={30} />
            </CenteredBox>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflowY: "auto",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {list && list.length > 0 ? (
                  list.map((item) => (
                    <Chip
                      label={`${item.name} ${item.surname}`}
                      key={item._id}
                      sx={{ m: 1 }}
                      // clickable
                      color={
                        selectedItems.includes(item) ? "primary" : "default"
                      }
                      icon={selectedItems.includes(item) ? <Done/> : " "}
                      onClick={() => {handleChipClick(item);}}
                    />
                  ))
                ) : (
                  <NoDataComponent message="No Data Found" />
                )}
              </Box>
            </>
          )}
        </Box>
      </CustomDialog>
    </>
  );
};
export default BeneficiaryRegionUi;
